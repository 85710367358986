//viewQuotation
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import { ToWords } from "to-words";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Autocomplete,
  TableContainer,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import "./viewQuotation.css";
import UnitechHeader from "../image/header.png";
import UnitechFooter from "../image/footer.png";
import { viewQuotationGuide } from "./guide";
import { fetchAddresses } from "../invoice/productDetails";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
    },
  },
  row: {
    height: 5,
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  cell50: {
    padding: 1,
    width: "50%",
    height: 5,
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 50,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 50,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 30,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 50,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
  spacialCaseBorder: {
    borderBottom: "1px solid black !important",
  },
});

function InstrumentTable({
  classes,
  quotationProductDetails,
  productList1,
  quotationAllData,
}) {
  const toWords = new ToWords();
  let productDeatails = quotationProductDetails;
  let productList = productList1;
  const hasDiscount =
    Array.isArray(productDeatails) &&
    productDeatails?.some((product) => product?.discount !== null);

  var amounts = {
    totalAmountBeforeTax: 0,
    gst: 0,
    sgst: 0,
    cgst: 0,
    conveyanceCharges: 0,
    courierCharges: 0,
    grandTotal: 0,
    discount: 0,
  };

  if (productDeatails.length > 0 && productList.length > 0) {
    for (let i = 0; i < productDeatails.length; i++) {
      const result = productList.find(
        ({ id }) => id === productDeatails[i].equipmentId
      );
      productDeatails[i].name = result?.instrumentName;
      let totalAmount = productDeatails[i].rate * productDeatails[i].quantity;
      let discountAmount =
        (totalAmount * (parseFloat(productDeatails[i]?.discount) || 0)) / 100;
      productDeatails[i].amount = totalAmount - discountAmount;
      amounts.totalAmountBeforeTax += productDeatails[i].amount;
    }
    amounts.conveyanceCharges = parseInt(
      quotationAllData[0]?.conveyanceCharges
        ? quotationAllData[0]?.conveyanceCharges
        : "0"
    );
    amounts.courierCharges = parseInt(
      quotationAllData[0]?.courierCharges
        ? quotationAllData[0]?.courierCharges
        : "0"
    );
    amounts.totalAmountBeforeTax +=
      amounts.conveyanceCharges + amounts.courierCharges;

    amounts.discount = quotationAllData[0]?.discount;
    let discountcal = (amounts.totalAmountBeforeTax * amounts.discount) / 100;

    amounts.totalAmountBeforeTax = amounts.totalAmountBeforeTax - discountcal;

    const gstType = quotationAllData[0]?.gstType;
    const gstPercentages = quotationAllData[0]?.gstpercentages;

    if (gstType === 1) {
      const percentage = gstPercentages ? gstPercentages / 2 : 9;
      amounts.sgst = amounts.cgst =
        (amounts.totalAmountBeforeTax * percentage) / 100;
      amounts.gst = amounts.sgst + amounts.cgst;
    } else if (gstType === 2) {
      amounts.gst =
        (amounts.totalAmountBeforeTax * (gstPercentages || 18)) / 100;
    } else {
      amounts.gst = 0;
    }
    amounts.grandTotal = amounts.gst + amounts.totalAmountBeforeTax;
  }

  return (
    <Table mt={5} className={["single-border-table", classes.table]}>
      <TableRow style={{ backgroundColor: "#D9D9D9" }} id="pagbreaksborder">
        <TableCell className={classes.srCell} align="center">
          <b>Sr. No</b>
        </TableCell>
        <TableCell className={classes.nameCell} align="center">
          <b>Description of Item</b>
        </TableCell>
        <TableCell className={classes.nameCell} align="center">
          <b>Range/Capacity</b>
        </TableCell>
        {/* <TableCell className={classes.makeCell} align="center">
          <b>SAC | HSN Code Job Type</b>
        </TableCell> */}
        <TableCell className={classes.rangeCell} align="center">
          <b>Type of Service</b>
        </TableCell>
        <TableCell className={classes.rangeCell} align="center">
          <b>Rate</b>
        </TableCell>
        <TableCell className={classes.qntCell} align="center">
          <b>Quantity (No)</b>
        </TableCell>
        {hasDiscount && (
          <TableCell className={classes.qntCell} align="center">
            <b>Discount (%)</b>
          </TableCell>
        )}
        <TableCell className={classes.rateCell} align="center">
          <b>Total Price (INR)</b>
        </TableCell>
      </TableRow>
      {productList &&
        productDeatails?.map((product, i) => (
          <TableRow className={classes.row} key={i} id="pagbreaksborder">
            <TableCell className={classes.srCell} align="center">
              {i + 1}
            </TableCell>
            <TableCell className={classes.nameCell} align="center">
              {product?.name}
            </TableCell>
            <TableCell className={classes.nameCell} align="center">
              {product?.description}
            </TableCell>
            {/* <TableCell className={classes.makeCell} align="center">
              {product?.hsnCode}
            </TableCell> */}
            <TableCell className={classes.rangeCell} align="center">
              {product?.service}
            </TableCell>
            <TableCell className={classes.qntCell} align="center">
              {product?.rate?.toFixed(2)}
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              {product?.quantity}
            </TableCell>
            {hasDiscount ? (
              product?.discount !== null ? (
                <TableCell className={classes.rateCell} align="center">
                  {product?.discount} %
                </TableCell>
              ) : (
                <TableCell
                  className={classes.rateCell}
                  align="center"
                ></TableCell>
              )
            ) : null}
            <TableCell className={classes.rateCell} align="center">
              {product?.amount?.toFixed(2)}
            </TableCell>
          </TableRow>
        ))}
      {amounts.conveyanceCharges !== 0 && (
        <TableRow id="pagbreaksborder">
          <TableCell
            className={classes.nameCell}
            colSpan={hasDiscount ? 7 : 6}
            align="right"
          >
            <b>Onsites Charges &nbsp;</b>
          </TableCell>
          <TableCell className={classes.rateCell} align="center">
            <b>
              {" "}
              {amounts.conveyanceCharges
                ? amounts.conveyanceCharges?.toFixed(2)
                : 0}
            </b>
          </TableCell>
        </TableRow>
      )}
      {amounts.courierCharges !== 0 && (
        <TableRow id="pagbreaksborder">
          <TableCell
            className={classes.nameCell}
            colSpan={hasDiscount ? 7 : 6}
            align="right"
          >
            <b> Courier Charges &nbsp;</b>
          </TableCell>
          <TableCell className={classes.rateCell} align="center">
            <b>
              {amounts.courierCharges ? amounts.courierCharges?.toFixed(2) : 0}
            </b>
          </TableCell>
        </TableRow>
      )}
      {amounts?.discount ? (
        <TableRow id="pagbreaksborder">
          <TableCell
            className={classes.nameCell}
            colSpan={hasDiscount ? 7 : 6}
            align="right"
          >
            <b>Discount&nbsp;</b>
          </TableCell>
          <TableCell className={classes.rateCell} align="center">
            <b>{amounts?.discount ? `${amounts?.discount} %` : ""}</b>
          </TableCell>
        </TableRow>
      ) : (
        ""
      )}
      <TableRow id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 7 : 6}
          align="right"
        >
          <b>Total Amount &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b>{amounts.totalAmountBeforeTax?.toFixed(2)}</b>
        </TableCell>
      </TableRow>
      {quotationAllData?.[0]?.gstType === 1 && (
        <>
          <TableRow id="pagbreaksborder">
            <TableCell
              className={classes.nameCell}
              colSpan={hasDiscount ? 7 : 6}
              align="right"
            >
              <b>{`CGST(${(quotationAllData?.[0]?.gstpercentages || 18) / 2}%) `}</b>
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              <b>{(amounts?.gst / 2)?.toFixed(2)}</b>
            </TableCell>
          </TableRow>
          <TableRow id="pagbreaksborder">
            <TableCell
              className={classes.nameCell}
              colSpan={hasDiscount ? 7 : 6}
              align="right"
            >
              <b>{`SGST(${(quotationAllData?.[0]?.gstpercentages || 18) / 2}%) `}</b>
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              <b>{(amounts?.gst / 2)?.toFixed(2)}</b>
            </TableCell>
          </TableRow>
        </>
      )}
      {(quotationAllData?.[0]?.gstType === 2 ||
        quotationAllData?.[0]?.gstType === 3) && (
        <>
          <TableRow id="pagbreaksborder">
            <TableCell
              className={classes.nameCell}
              colSpan={hasDiscount ? 7 : 6}
              align="right"
            >
              <b>{`IGST(${quotationAllData?.[0]?.gstpercentages || 18}%) `}</b>
            </TableCell>
            <TableCell className={classes.rateCell} align="center">
              <b>{amounts.gst?.toFixed(2)}</b>
            </TableCell>
          </TableRow>
        </>
      )}
      <TableRow style={{ backgroundColor: "#D9D9D9" }} id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 7 : 6}
          align="right"
        >
          <b>Grand Total Amount &nbsp;</b>
        </TableCell>
        <TableCell className={classes.rateCell} align="center">
          <b>{amounts.grandTotal ? amounts.grandTotal?.toFixed(2) : ""}</b>
        </TableCell>
      </TableRow>
      <TableRow id="pagbreaksborder">
        <TableCell
          className={classes.nameCell}
          colSpan={hasDiscount ? 8 : 7}
          align="left"
        >
          <div>
            <b style={{ backgroundColor: "#D9D9D9", padding: "1px 5px" }}>
              &nbsp; Amount in Words:
            </b>
            <b>
              &nbsp;
              {toWords.convert(
                amounts.grandTotal ? amounts.grandTotal?.toFixed(2) : 0,
                { currency: true }
              )}
            </b>
          </div>
        </TableCell>
      </TableRow>
    </Table>
  );
}

export default function EditQuotation() {
  const viewQuotationGuides = viewQuotationGuide();
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const params = useParams();
  let loginName = localStorage.getItem("userName") || "";
  const [productList, setProductList] = React.useState([]);
  const [details, setDetails] = React.useState({
    companyName: "",
    companyNumber: "",
    clientName: "",
    address: "",
    referenceNumber: "",
    quotationNumber: "",
    subject: "",
    Date: "",
    thankYouForEnquiry: "",
    termsConditions: "",
    clientId: "",
    conveyanceCharges: "",
    gstNumber: "",
    email: "",
  });
  const [quotationProductDetails, setProductDetails] = React.useState([]);
  const [quotationAllData, setquotatioAllData] = React.useState([]);
  const [settings, setSettings] = React.useState({});
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [contact, setContact] = React.useState({
    contname: "",
    email: "",
    contactNumber: "",
  });
  const [newAddressArray, setNewAddressArray] = React.useState([]);
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

 const generatePDF = async () => {
  setDownlaodPdfLoading(true);
  try {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [97, 1, 60, 1],
        filename: `certificate_${params.id}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
          compress: true,
        },
      })
      .toPdf()
      .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("helvetica", "normal");

         // border add for contnet
         const borderWidth = 0.6;
         const leftMargin = 15;
         const borderX = leftMargin;
         const borderY = 92;
         const rightMargin = 595 - leftMargin * 0.95;
         pdf.setLineWidth(borderWidth);
         pdf.rect(borderX, borderY, rightMargin - leftMargin, 696);

        // let image = await generateDynamicHeader(
        //   ulrNumber,
        //   certificateNumber,
        //   i,
        //   totalPages
        // );
        // pdf.addImage(image, 0, 70, 595, 55);

        let image = await generateDynamicFooter(i, totalPages);
        pdf.addImage(image, 0, 57, 595, 55);

        if (letterHead) {
          let image = await generateLetterHeadHeader();
          pdf.addImage(image, 0, 11, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 798, 595, 30);
      }

      if (electronicSign) {
        pdf.setFontSize(9);
        const text = "This is computer generated document, hence signature not required";
        const textWidth =
          (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const x = (pageWidth - textWidth) / 2;
        pdf.text(text, x, 798);
      }

    }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${quotationAllData[0]?.companyName}_${params.quotationNumber}.pdf`;
      a.click();
      // pdf.save();
      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  function fetchSettings() {
    axiosWithToken.get(BASE_URL + `settings`).then((res) => {
      let _settingList = {};
      res.data.forEach((setting) => {
        _settingList[setting.keyName] = setting.value;
      });
      setSettings(_settingList);
    }).catch((err)=>{
      if (err.message !== "request_aborted") {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchSettings" ,"View Quotation")}><u>Error: {err.message}</u></h6>);
      }
    })
  }
  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from customProducts as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getProductList" ,"View Quotation")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const getQuat = () => {
    let url = BASE_URL;
    let paylaod = {
      query: `SELECT qt.*,qt.address as qt_address,qt.id as qt_id, client.* FROM quotation AS qt JOIN clients AS client ON qt.clientId = client.id WHERE qt.clientId = ${params.clientId} AND qt.quotationNumber LIKE '${params.quotationNumber}'`,
    };
    axiosWithToken
      .post(url + "dynamic", paylaod)
      .then(async (res) => {
        let sqlQuery = {
          query: `SELECT * FROM productDetails WHERE type = 1 AND referenceId = ${res.data[0]?.qt_id}`,
        };
        const response = await axiosWithToken.post(
          BASE_URL + `dynamic`,
          sqlQuery
        );
        setProductDetails(response.data);
        let contact = res.data[0].contact
          ? JSON.parse(res.data[0].contact)
          : null;
        setquotatioAllData(res.data);
        setContact({
          contname: contact && contact[0]?.contactPersonName || "",
          email: contact && contact[0]?.emailId || "",
          contactNumber: contact && contact[0]?.contact || "",
        });
      })
      .catch((err) => {
        console.log("getquat", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getQuat" ,"View Quotation")}><u>Error: {err.message}</u></h6>);
        }
      });
  };
  useEffect(() => {
    // getQuotations();
    fetchSettings();
    getProductList();
    getQuat();
  }, []);

  useEffect(async () => {
    let splitAddress = quotationAllData[0]?.qt_address?.split(",");
    let newAddressArray = await fetchAddresses(
      splitAddress?.[0],
      splitAddress?.[1]
    );
    setNewAddressArray(newAddressArray);
  }, [quotationAllData[0]?.qt_address]);

  return (
    <>
      <Paper sx={{ mx: 5, p: 2 }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                viewQuotationGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>
        <Grid container spacing={2} align="left" style={{ display: "flex" }}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="quotation_view_letterhead"
              options={[
                { id: 1, label: "With Letter Head" },
                { id: 2, label: "Without Letter Head" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="letterHead" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Letter Head" ||
                  newInputValue === ""
                ) {
                  setLetterHead(true);
                } else if (newInputValue === "Without Letter Head") {
                  setLetterHead(false);
                }
                // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="quotation_view__electronicsign"
              options={[
                { id: 1, label: "With Electronically Signed" },
                { id: 2, label: "Without  Electronically Signed" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Electronically Signed" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Electronically Signed" ||
                  newInputValue === ""
                ) {
                  setElectronicSign(true);
                } else if (newInputValue === "Without  Electronically Signed") {
                  setElectronicSign(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              id="quotation_view_Download"
              variant="contained"
              size="small"
              sx={{ ml: 3 }}
              disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
              onClick={() => {
                generatePDF();
              }}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
        <br />
        <div
          className="invoice"
          ref={printComponentRef}
          id="quotation_view__print"
        >
          {quotationAllData ? (
            <div
              style={{
                width: "95%",
                // border: "1px solid black",
                textAlign: "center",
                margin: "0px auto",
                padding: "10px 10px 5px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  md={12}
                  className="font-link"
                  align="center"
                  justifyContent={"center"}
                >
                  <h5 style={{ color: "#000066", margin: "5px 0px" }}>
                    <b>QUOTATION FOR CALIBRATION & TESTING SERVICES</b>
                  </h5>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, fontSize: "0.875rem", fontWeight: 400 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b>Customer</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: "28%", borderRight: "none" }}
                      >
                        {quotationAllData[0]?.companyName}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b>Enquiry ID | Date</b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" }}>
                      {quotationAllData[0]?.enquiryId ? `${quotationAllData[0]?.enquiryId} | ` :""}{moment(quotationAllData[0]?.enquiryDate).format(
                          "DD/MM/YYYY"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b>Billing Address</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: "28%", borderRight: "none" }}
                      >
                        {newAddressArray?.[0]?.address}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b>Quotation Number | Date</b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" }}>
                        {quotationAllData[0]?.quotationNumber ? `${quotationAllData[0]?.quotationNumber} | ` :""}
                        {moment(quotationAllData[0]?.date).format("DD/MM/YYYY")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          width: "22%",
                          borderRight: "none",
                        }}
                      >
                        <b>Contact Person</b>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: "28%", borderRight: "none" }}
                      >
                        {contact?.contname}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight: "none",
                          width: "22%",
                        }}
                      >
                        <b>Contact Number</b>
                      </TableCell>
                      <TableCell align="left" style={{ width: "28%" }}>
                        {contact?.contactNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b>E-mail</b>
                      </TableCell>
                      <TableCell align="left" style={{ borderRight: "none" }}>
                        {contact?.email}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b>Quotation Validity</b>
                      </TableCell>
                      <TableCell align="left">
                        30 Days
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b>{newAddressArray?.[0]?.shipping?.address ? (
                          <b>Shipping Address</b>
                        ) : (
                          ""
                        )}</b>
                      </TableCell>
                      <TableCell align="left" style={{ borderRight: "none" }}>
                      {newAddressArray?.[0]?.shipping?.address}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b>Quotation Validity</b>
                      </TableCell>
                      <TableCell align="left">
                        30 Days
                      </TableCell>
                    </TableRow> */}
                    {/* <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        {newAddressArray?.[0]?.shipping?.address ? (
                          <b>Shipping Address</b>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          borderRight: "none",
                        }}
                      >
                        {newAddressArray?.[0]?.shipping?.address}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "600",
                          backgroundColor: "#D9D9D9",
                          borderRight: "none",
                        }}
                      >
                        <b>Equipment Collection</b>
                      </TableCell>
                      <TableCell align="left">
                        {quotationAllData[0]?.equipmentCollection}
                      </TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  backgroundColor: "#D9D9D9",
                  textAlign: "left",
                  border: "1px solid black",
                  padding: "3px 10px",
                  display: "flex",
                  gap: "0px",
                  flexDirection: "column",
                  fontSize: "13px",
                  borderBottom: "none",
                }}
              >
                <b>Respected Sir/Madam:</b>
                <b style={{ fontWeight: 500 }}>
                With reference to above mentioned subject, Please find enclosed herewith our offer as under to suit your requirement:
                </b>
                {/* <b>
                  Enquiry: +91 98406 72352 | Quotation: +91 99405 64783 |
                  Operations: +91 99400 47321
                </b> */}
              </div>

              <InstrumentTable
                classes={classes}
                quotationProductDetails={quotationProductDetails}
                productList1={productList}
                quotationAllData={quotationAllData}
              ></InstrumentTable>

              <div
                style={{
                  fontSize: "13px",
                  // pageBreakInside: "avoid",
                  paddingTop: "2px",
                }}
              >
                <div className="font-link-footer">
                  <h6>
                    <b>Standard Terms & Conditions</b>
                  </h6>
                  <div
                    style={{
                      whiteSpace: "pre-wrap",
                      padding: "1px 5px",
                      textAlign: "justify",
                    }}
                  >
                    {/* {quotationAllData[0]?.termsConditions ||
                      settings["quotation terms"]} */}
                      <ul style={{paddingLeft:"5px"}}>
                            <li style={{pageBreakInside: "avoid",fontSize:"15px"}}>
                                <b>Add-on Features of our Service Offering:</b>
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              a) Our calibration laboratories are accredited as per ISO/IEC 17025:2017 (NABL-India) for the Mechanical (Dimensional, Pressure, Mass, Volume, etc.); Electro-Technical and Thermal inclusive of humidity parameter.
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              b) Pickup and Delivery service will be under customer scope.
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              c) Quick Turnaround time – max 5 working days which can be further shortened with prior appointment/schedule.
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              d) Repair/ servicing facility for majority of measuring instruments through our dedicated service partners.
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              e) We can extend our support during your quality audits and can attend to queries related to calibration on your behalf as your trusted partner.
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              f) We also arrange out of scope calibration from accredited Third Party Calibration Laboratories as an add-on service. Archerchem Calibrations will work as Single Point Contact for your all calibration needs.
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                            g) Charges will be applicable as decided when found an instrument is defective/out of tolerance/ hysteresis error while calibrating.
                            </li>
                            <li style={{pageBreakInside: "avoid",fontSize:"15px"}}>
                            <b>Commercial Terms & Conditions:</b> 
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              1) Calibration Charges - The price indicated is applicable only for calibration and any additional service like repair / servicing, will be charged extra.
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              2) GST - Goods and Services Tax (GST) @ 18 % will be charged extra on the total billing. (if any other taxes applicable at the time of billing, are in the scope of customer)
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              3) Payment - 100% in advance through DD/ Cheque in favour of Archercal Pvt. Ltd.
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              4) Delivery (In house) – In five working days along with calibration certificate.
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              5) Delivery (On-site) – All certificates will be issued in five working days after completion of site work.
                            </li>
                            <li style={{pageBreakInside: "avoid"}}>
                              6) Dispatch by courier: The instruments will be sent on “COD basis only” and packing material will be used as received from customer.
                            </li>
                              <li style={{pageBreakInside: "avoid"}}>
                              <b style={{fontSize:"15px"}}>Note - </b>
                              Archercal Pvt. Ltd. shall not be held responsible for any damage or loss of the instruments during calibration or in transit irrespective of the fact that required care will be taken while calibration/packing. You may provide Transit insurance for your instruments while sending for calibration.
                              </li>
                              <li style={{pageBreakInside: "avoid"}}>
                              <b style={{fontSize:"15px"}}>Special Note :</b> We hope you will find our offer competitive and in line with your requirement. Should you have any further queries or clarifications, please feel free to contact us on +91-9821596569.Thanking you and assuring you our best services always, we remain,
                              </li>
                              <li style={{pageBreakInside: "avoid",fontSize:"15px"}}>
                                <b>
                              Thanking you and assuring you our best services always, we remain.
                                </b>
                              </li>
                                <li style={{pageBreakInside: "avoid"}}>Please send your instrument details duly filled with Service Request Form.
                                </li>
                                <li style={{pageBreakInside: "avoid",textAlign:"right",fontSize:"15px"}}><b>Yours Sincerely,</b></li>
                                <li style={{pageBreakInside: "avoid",textAlign:"right",fontSize:"15px"}}><b>
                                  For Archercal Private Limited (Formerly known as Archerchem Calibrations Pvt Ltd)
                                  </b>
                                </li>
                                <li style={{pageBreakInside: "avoid",textAlign:"right",fontSize:"15px",textTransform:"capitalize"}}><b>
                                ( {loginName} ) 
                                  </b>
                                </li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}

          <div style={{ width: "100%" }} id="header-section"></div>
        </div>
        {downloadPdfloading && (
          <DownloadPdfLoaderModal
            loading={downloadPdfloading}
            name={"Quotation"}
          />
        )}
          {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
      </Paper>
    </>
  );
}
